<template>
  <div>
    <v-menu>
      <template #activator="{ isActive, props }">
        <v-btn
          variant="text"
          v-bind="props"
          :append-icon="isActive ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          class="px-0 font-bold"
        >
          {{ localeProperties.name }}
        </v-btn>
      </template>
      <v-list style="z-index: 3000 !important">
        <NuxtLink
          v-for="(item, index) in availableLocales"
          :key="index"
          :to="switchLocalePath(item.code)"
        >
          <v-list-item
            style="z-index: 3000 !important"
            class="cursor-pointer"
            :value="item.code"
          >
            {{ item.name }}
          </v-list-item>
        </NuxtLink>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
const { locale, locales, localeProperties } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const availableLocales = computed(() => {
  return locales.value.filter((i) => i.code !== locale.value)
})
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
<style>
.v-overlay__content {
  z-index: 9999 !important;
}
.v-btn__content {
  text-transform: capitalize;
}
</style>
