<template>
  <div class="layout-container">
    <v-layout full-height>
      <ClientOnly>
        <v-app-bar flat height="80">
          <TTHeader class="layout__header container" />
        </v-app-bar>
      </ClientOnly>
      <v-main scrollable>
        <div id="start" class="layout__wrapper">
          <div fluid class="container">
            <NuxtPage :key="$route.fullPath" />
          </div>
        </div>
        <ClientOnly>
          <TTFooter class="layout__footer" />
        </ClientOnly>
      </v-main>
      <SharedDayProduct />
      <ModalRequest v-if="showRequestOrderModal" />
    </v-layout>
    <div v-if="!isCountry">
      <SharedCountrySelect />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIndexStore } from '~/stores'
import { useAuthStore } from '~/stores/auth'
import { useCartStore } from '~/stores/cart'
import { useCitiesStore } from '~/stores/cities'
import { useRequestModalStore } from '~/stores/requestModal'

const cartStore = useCartStore()
const authStore = useAuthStore()
const cityStore = useCitiesStore()
const indexStore = useIndexStore()
const requestModalStore = useRequestModalStore()
const route = useRoute()
const router = useRouter()

const isCountry = computed(() => indexStore.is_country)
const showRequestOrderModal = computed(
  () => requestModalStore.showRequestOrderModal
)

onMounted(async () => {
  if (useCookie('token').value) {
    await authStore.fetchUser()
  }
  await indexStore.getCountry()
  if (!router.currentRoute.value.path.includes('order')) {
    await cartStore.fetchProductsToBasket()
  }
  await authStore.setInitialState()
  await cityStore.getCities()

  const pathSegments = route.fullPath.split('/')
  const citySlugFromUrl = pathSegments[2]

  const cityFromLocalStorage = JSON.parse(localStorage.getItem('city') || '{}')
  const foundCity = indexStore.countries.find(
    (city) => city.slug === citySlugFromUrl
  )
  if (foundCity) {
    localStorage.setItem('city', JSON.stringify(foundCity))
  } else if (cityFromLocalStorage) {
    if (!pathSegments.includes(cityFromLocalStorage.slug)) {
      pathSegments.splice(2, 0, cityFromLocalStorage.slug)
      const newPath = pathSegments.join('/')
      router.push(newPath)
    }
  } else {
    console.warn('No city found in URL or localStorage')
  }
})
</script>

<style scoped lang="scss">
.layout {
  &__wrapper {
    flex: 0 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    @media (min-width: 768px) {
      padding: 0;
    }
    @media (min-width: 1024px) {
      // padding: 0 100px;
    }
  }

  &__header {
    flex: 0 0 auto;
    @media (min-width: 768px) {
      // padding: 0 1rem;
    }
    @media (min-width: 1024px) {
      // padding: 0 100px;
    }
  }

  &__footer {
    flex: 0 0 auto;

    @media (min-width: 768px) {
      padding: 0 1rem;
    }
    @media (min-width: 1024px) {
      padding: 0 100px;
    }
  }
}
</style>
