export const useCitiesStore = defineStore({
  id: 'cities',
  state: () => ({
    cities: [],
    activeCity: null,
    isLoading: true,
    showModal: false,
    isCityOne: false,
  }),
  getters: {
    ACTIVE_CITY(state) {
      return state.activeCity
    },
  },
  actions: {
    setShowModal(name) {
      this.showModal = name
    },
    setActiveCityOne(city) {
      this.activeCity = city
      localStorage.setItem('city', JSON.stringify(city))
      this.isCityOne = true
    },
    getCities() {
      this.isLoading = true
      useApi()
        .$get('cities')
        .then((res) => {
          this.cities = res
          if (res.length <= 1 && !this.activeCity) {
            this.setActiveCityOne(res[0])
            setTimeout(() => {
              location.reload()
            }, 100)
          }
        })
        .catch((err) => {
          console.error('An error occurred while fetching cities:', err)
        })
        .finally(() => (this.isLoading = false))
    },
    setActiveCity(city) {
      this.activeCity = city
      localStorage.setItem('city', JSON.stringify(city))
    },
    loadCity() {
      const savedCity = localStorage.getItem('city')
      if (savedCity) {
        try {
          const parsedCity = JSON.parse(savedCity)
          if (parsedCity && parsedCity.name && parsedCity.url) {
            this.activeCity = parsedCity
          }
        } catch (error) {
          console.error('Error parsing city from localStorage:', error)
        }
      } else {
        this.showModal = true
      }
    },
  },
})
