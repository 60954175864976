<template>
  <div class="relative">
    <div variant="text" stacked @click="handleClick">
      <v-badge
        v-if="productsLength"
        color="primary"
        :content="productsLength"
      >
        <div v-html="basket" />
      </v-badge>
      <div v-else v-html="basket" />
    </div>
    <Teleport to="body">
      <Transition name="slide-fade">
        <div v-if="isOpen" class="basket-drawer md:!w-[544px] w-full">
          <div class="h-full flex flex-col">
            <div class="basket-drawer-navbar border-b">
              <h2 class="text-[28px] font-medium">
                {{ $t('OrderBasket_views_cart_title') }}
              </h2>
              <div class="basket-drawer-navbar__icon" @click="isOpen = false">
                <v-icon>mdi-close</v-icon>
              </div>
            </div>
            <div class="flex h-full min-h-0 flex-col justify-between">
              <template v-if="productsLength">
                <div class="overflow-y-scroll px-7">
                  <TTHeaderBasketCard
                    v-for="(product, index) of products"
                    :key="index"
                    class="last:mb-4"
                    :product
                  />
                </div>
                <div v-if="recommendations.length" class="border-t px-7 pt-7">
                  <h1 class="text-[22px] mb-5">
                    {{ $t('home_cart_recommendations_title') }}
                  </h1>
                  <div class="overflow-x-scroll snap-x flex gap-x-4">
                    <TTProductRecommend
                      v-for="recommendation in recommendations"
                      :key="recommendation.id"
                      class="snap-start"
                      :product="recommendation"
                      @click="isOpen = false"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="basket-empty">
                  <div v-html="emptyBasketIcon" />
                  <div class="basket-empty__text">
                    {{ $t('OrderBasket_views_validation_cart_empty') }}
                  </div>
                </div>
              </template>
              <div v-if="productsLength" class="p-7 border-t">
                <div class="flex justify-between mb-5">
                  <p class="text-[20px] font-inter">
                    {{ $t('main_vpopup_modifier_summ') }}
                  </p>
                  <p class="font-inter">
                    {{ totalPrice.toLocaleString() }} &nbsp₸
                  </p>
                </div>
                <BaseButton
                  variant="main"
                  :text="$t('home_cart_Basket_btn2create_order')"
                  @click="goToOrder"
                />
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>

    <v-bottom-sheet v-model="isOpen" />
  </div>
</template>
<script setup lang="ts">
import { basket, emptyBasketIcon } from '@/config/icon.config'
import { useIndexStore } from '~/stores'
import { useCartStore } from '~/stores/cart'

const localePath = useLocalePath()
const store = useIndexStore()
const cartStore = useCartStore()

const isOpen = ref(false)

const productsLength = computed(() => cartStore.products.length)
const products = computed(() => cartStore.products)
const totalPrice = computed(() => cartStore.amount)
const recommendations = computed(() => cartStore.recommendations)

const isMobileDevice = () => window.innerWidth <= 768

function handleClick() {
  if (isMobileDevice()) goToOrder()
  else isOpen.value = true
}

function goToOrder() {
  navigateTo(localePath(`/${store.current_county?.slug}/order`))
  // router.push(`/${locale.value}/${store.current_county.slug}/order`)
  isOpen.value = false
}
</script>

<style scoped lang="scss">
.country {
  &-list {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  &-select {
    padding: 20px;
  }

  &-name {
    font-weight: 600;
    cursor: pointer;
    margin: 0 20px 0 2px;
  }
}
.basket {
  position: relative;
  &-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100svh;
    flex-direction: column;
    &__text {
      font-size: 20px;
      font-family: 'Inter', serif;
      font-weight: 500;
      color: #989898;
    }
  }
}
.basket-drawer {
  background: white;
  position: fixed;
  height: 100%;
  right: 0;
  z-index: 9999;
  &-navbar {
    padding: 28px;
    display: flex;
    justify-content: space-between;
    &__icon {
      cursor: pointer;
    }
  }
  &-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background-color: white;
    padding: 10px;
    &-info {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    &-button {
      background: #25b6bf;
      margin-top: 30px;
      padding: 10px;
      cursor: pointer;
      gap: 10px;
      color: white;
      width: 100%;
      height: 50px;
      border-radius: 10px;
    }
  }
}
</style>
<style>
.slide-fade-enter-active {
  transition: all 0.1s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
