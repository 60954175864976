<template>
  <div>
    <div class="text-center overflow-hidden">
      <v-dialog
        v-if="product && isShown"
        v-model="dialog"
        activator="parent"
        width="auto"
        @update:model-value="updateDialog"
      >
        <v-card
          width="360"
          height="515"
          style="padding: 10px; overflow: hidden"
        >
          <v-toolbar style="background: none">
            <h3 class="ml-4">{{ $t('main_ProductOfTheDay_title') }}</h3>
            <v-spacer />
            <v-btn icon dark @click="closeHandler">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <div v-if="product && product?.images">
              <v-img width="292" height="292" :src="product.images[0].img" />
              <div class="product-card__info">
                <div class="product-card__name">{{ product.name }}</div>
                <div class="product-card__cost">{{ product.price }}&nbsp₸</div>
              </div>
              <v-card-actions>
                <v-btn
                  variant="flat"
                  color="main"
                  width="100%"
                  @click="selectHandler"
                  >Выбрать
                </v-btn>
              </v-card-actions>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProductStore } from '@/stores/product'
import { useCartStore } from '~/stores/cart'

const cartStore = useCartStore()
const store = useProductStore()

const dialog = ref(true)
const product = computed(() => {
  return store.dayProduct
})
const isShown = computed(() => {
  return store.isShownProductDay
})
function updateDialog() {
  store.setIsShownToTrue()
}
function closeHandler() {
  dialog.value = false
  store.setIsShownToTrue()
}
function selectHandler() {
  const data = {
    ...product.value,
    amount: 1,
  }
  cartStore.setProductToBasket(data)
  store.setIsShownToTrue()
  dialog.value = false
}
</script>

<style scoped lang="scss">
.product-card {
  &__wrapper {
    padding: 16px;
    border-radius: 8px;
    border: 1px solid transparent;
  }

  &__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 0;
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
  }

  &__cost {
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
