<template>
  <nav class="flex gap-8 flex-nowrap">
    <NuxtLinkLocale
      v-for="item in routes || []"
      :key="item.path"
      class="text-dark-gray whitespace-nowrap hover:text-main font-bold"
      :class="[
        isActive(item.path)
          ? 'text-main border-solid border-b-2 border-b-main'
          : '',
      ]"
      :value="item.path"
      :to="item?.path"
    >
      {{ $t(item?.name) }}
    </NuxtLinkLocale>
  </nav>
</template>

<script setup lang="ts">
import { useIndexStore } from '~/stores'

const indexStore = useIndexStore()
const router = useRouter()
const selectedTab = ref<null | number>(null)

const routes = [
  {
    path: `/${indexStore.current_county?.slug}`,
    name: 'navbar_menu',
  },
  {
    path: `/${indexStore.current_county?.slug}/bonus`,
    name: 'navbar_bonus',
  },
  {
    path: `/${indexStore.current_county?.slug}/about`,
    name: 'navbar_aboutus',
  },
  {
    path: `/${indexStore.current_county?.slug}/contacts`,
    name: 'navbar_contacts',
  },
  {
    path: `/${indexStore.current_county?.slug}/franchising`,
    name: 'navbar_franchise',
  },
]

function isActive(path: string) {
  if (path === routes[0].path) return false
  return router.currentRoute.value.path.includes(path)
}

function updateSelectedTab() {
  const tab = router.currentRoute.value.path
  routes.forEach((route, index) => {
    if (tab.includes(route.path)) selectedTab.value = index
  })
}

watch(
  () => router.currentRoute.value.path,
  () => {
    updateSelectedTab()
  },
  { immediate: true }
)

onMounted(() => {
  updateSelectedTab()
})
</script>

<style scoped lang="scss">
.nav-bar {
  &__link {
    color: #989898;
  }
  &__tab {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;

    &:hover {
      color: rgb(var(--v-theme-main));
    }
  }
}
</style>
